// Base CSS
import './assets/css/app.css';
import './assets/css/fonts.css';
import './assets/css/switch.css';

import Header from './components/header';
import Footer from './components/footer';

// Pages
import Landing from './views/Landing';
import Executive from './views/Executive';
import Contact from './views/Contact';
import Community from './views/Community';
import Luthier from './views/Luthier';
import Counsel from './views/Counsel';
import Governance from './views/Governance';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

function App() {
    return (
        <div className="App">
            <Router>
                <Header />
                <main role="main">
                    <AnimatedSwitch
                        atEnter={{ opacity: 0 }}
                        atLeave={{ opacity: 0 }}
                        atActive={{ opacity: 1 }}
                        className="switch-wrapper">
                        <Route exact path="/" component={Landing} />
                        <Route path="/executive" component={Executive} />
                        <Route path="/counsel" component={Counsel} />
                        <Route path="/governance" component={Governance} />
                        <Route path="/community" component={Community} />
                        <Route path="/luthier" component={Luthier} />
                        <Route path="/contact" component={Contact} />
                    </AnimatedSwitch>
                </main>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
